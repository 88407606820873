<template>
  <div class="Note">
    <h1>业务说明</h1>
  </div>
</template>

<script>
export default {
  name: 'Note',
  created (opt) {
    // document.title = this.$t('m.about')

  },
  mounted(){
    // this.loading.show();
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.Note {
  margin-top: 0px;
}
</style>
